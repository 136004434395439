import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImg from '../../assets/images/home header background.webp'
import './Header.css'

const Header = () => {
  const headerMiniImg = require('../../assets/images/home header background mini.webp')
  const styles={
    backgroundImage: `url(${headerMiniImg})`,
    opacity:1
  }
  console.log(styles);
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  
  const navigate = useNavigate()

  useEffect(() => {
    const img = imgRef.current;

    const handleLoad = () => {
      setLoaded(true);
    };

    if (img.complete) {
      handleLoad();
    } else {
      img.addEventListener('load', handleLoad);
      // Cleanup the event listener
      return () => {
        img.removeEventListener('load', handleLoad);
      };
    }
  }, []); 
  console.log(headerMiniImg);
    return (
        <header style={styles} className={`blur-load main-header ${loaded ? 'loaded' : ''}`}>
            <img ref={imgRef}  className='header-img' src={headerImg} alt="header background" />
            <div className="header-content">
              <h1>Next Level
              <br />Automotive Service Inc.</h1>
              <p>A place where we exceed our customers expectations</p>
              <button onClick={()=>navigate('/service')} className="header-btn">View Services</button>
            </div>
        </header>
    );
};

export default Header;