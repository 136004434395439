import React from 'react';
import StaffCarousel from '../../components/Carousel/Carousel';
import staff1 from '../../assets/images/staff-1.jpg'
import staff2 from '../../assets/images/staff-2.jpg'
import staff3 from '../../assets/images/staff-3.jpg'
import staff4 from '../../assets/images/staff-4.jpg'
import './Staff.css'

const Staff = () => {
    const staff = [
        {
            name: "Andrew",
            position:"Founder",
            image:staff1,
            info:"Founder of Next Level Automotive Service highly driven in automotive repairs. Started the company in 2009 and his drive was and is to exceed our customers expectations. His passion was cars and seeing broken things come to life."
        },
        {
            name: "Yuri Yatsyshyn",
            position:"Head Technician",
            image:staff2,
            info:"Our head technician with many certificates in automotive technology and repair, is very knowledgeable. He goes the extra mile to get a job done quick and worth a quality results. Yurii performs text and modules before replacing them and shows the values that it must have to work properly. Ranked #1 youngest technician in Europe."
        },
        {
            name: "Michael A. Wheaton",
            position:"Service Adviser",
            image:staff3,
            info:"Michael is our service advisor who can answer any questions you might have about your vehicle repairs, whether it's a car, SUV, or light truck. You ask and he has an answer. He does what it takes you to get you back on the road."
        },
        {
            name: "Vladimir Litvinov",
            position:"Technician",
            image:staff4,
            info:"A young technician who holds safety and emission licenses and will thrilly look over your vehicle and show you what exactly is wrong with your car. Very friendly and driven to perfection."
        },
    ]
    return (
        <div>
            <h1 className='staff-title'>Our Team</h1>
            <StaffCarousel peopleList={staff}/>
        </div>
    );
};

export default Staff;