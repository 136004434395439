import React, { forwardRef } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import mapImage from "../../assets/images/map.png";
import "./Footer.css";

const Footer = forwardRef((props, ref) => {
	return (
		<footer ref={ref} id="contact">
			<div className="footer">
				<div className="container">
					<div className="titlepage">
						<h5>Contact Us</h5>
						<div className="contact-section">
							<ul className="location_icon">
								<li>1712 Brownsville rd Trevose pa 19053</li>
								<li>(215)674-9274</li>
								<li>NextLevelAutomotive1@gmail.com</li>
							</ul>
						</div>
					</div>
					<div className="footer-main-content">
						<div className="form-section">
							<form id="request" className="main_form">
								<div className="form-row">
									<div className="form-group">
										<input
											className="contactus"
											placeholder="Full Name"
											type="text"
											name="Full Name"
										/>
									</div>
									<div className="form-group">
										<input
											className="contactus"
											placeholder="Email"
											type="email"
											name="Email"
										/>
									</div>
									<div className="form-group">
										<input
											className="contactus"
											placeholder="Phone Number"
											type="text"
											name="Phone Number"
										/>
									</div>
									<div className="form-group">
										<textarea
											className="textarea"
											placeholder="Message"
											name="Message"
										></textarea>
									</div>
									<div className="button-group">
										<button className="send_btn">
											Send
										</button>
									</div>
									<div className="social-group">
										<ul className="social_icon">
											<li>
												<a
													rel="noreferrer"
													target="_blank"
													href="https://www.facebook.com/nextlevelautoinc"
												>
													<FacebookIcon />
												</a>
											</li>
											<li>
												<a
													rel="noreferrer"
													target="_blank"
													href="https://www.instagram.com/nextlevelautomotive1"
												>
													<InstagramIcon />
												</a>
											</li>
											<li>
												<a href="mailto:NextLevelAutomotive1@gmail.com">
													<EmailIcon />
												</a>
											</li>
										</ul>
									</div>
								</div>
							</form>
						</div>
						<div className="map-section">
							<div className="map">
								<figure>
									<img src={mapImage} alt="map" />
								</figure>
							</div>
							<form className="bottom-form">
								<h6>Newsletter</h6>
								<input
									className="enter"
									placeholder="Enter your email"
									type="text"
									name="Enter your email"
								/>
								<button className="sub_btn">Subscribe</button>
							</form>
						</div>
					</div>
				</div>
				<div className="copyright">
					<p>© 2024 Your Company. All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
});

export default Footer;
