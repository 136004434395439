import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logoImg from '../../assets/images/logo.png'
import './Navbar.css'

const pages = [
    {
        name:'Home',
        url:'/'
    },
    {
        name:'About',
        url:'/about'
    },
    {
        name:'Service',
        url:'/service'
    },
    {
        name:'Staff',
        url:'/staff'
    },
    {
        name:'Story',
        url:'/story'
    },
    {
        name:'Reviews',
        url:'/reviews'
    }
];


export const Navbar=({scrollToFooter})=>{
  const {pathname} = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const goToContact = ()=>{
    setAnchorElNav(null);
    scrollToFooter()
  }

  return (
    <AppBar style={{background:'#362a00'}} position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{display:"flex", justifyContent:"space-between"}} disableGutters>
          <Box 
            sx={{width:{xs:"150px", sm:"200px", md:"250px", lg:"320px", xl:"350px"}}} 
          >
            <img width="100%" src={logoImg} alt="Logo" />
          </Box>
          <Box sx={{ color:'#f8e703;', display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{background:'#f8e703'}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                mt: "1px", 
                "& .MuiMenu-paper":{ backgroundColor: "rgb(54 42 0)", }, 
              }}
            >
              {pages.map((page) => (
                <NavLink key={page.name} to={page.url}>
                  <MenuItem>
                    <Typography sx={{color:"white"}} textAlign="center">{page.name}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
              <MenuItem>
                <Typography onClick={goToContact} sx={{color:"white"}} textAlign="center">Contact</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'end' }}>
            {pages.map((page) => (
                <NavLink className="navbar-links" to={page.url} key={page.name}>
                    <Button
                      sx={{ my: 2, color: 'white', display: 'block' }}
                      >
                      {page.name}
                    </Button>
                    <hr />
                  </NavLink>
            ))}
              <Button
                onClick={goToContact}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Contact
              </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}